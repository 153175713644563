/*****************  Dotted link focus outline  ************/
/**********************************************************/
/* /core/libraries/slickSlider/assets/1.4.1/slick.css */
.slick-list:focus {
	outline: dotted;
}

/*******  Use relative than absolute unit values  *********/
/**********************************************************/
/* /core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
.typed {
	font: normal 1.875em/3.125em "Journal", Georgia, Times, serif;
}

/***  Foreground and background colors have contrast  *****/
/**********************************************************/
/* /core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
p.error {
	background-color: #ee0000;
	color: #fff;
}

/* /core/libraries/tablesaw/assets/2.0.3/tablesaw.bare.css */
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus {
	color: #1465a3;
	background-color: #fff;
}

/* /core/modules/files/assets/stylesheets/all.css */
.controller-manage-categories form fieldset .legend {
	background: #585656;
	color: #ffffff;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-list-container ul.fly-out-menu-list li a:active,
.fly-out-menu-list-container ul.fly-out-menu-list li.selected a {
	background: #11406f !important;
	color: #ffffff !important;
}

/* /core/public/shared/assets/css/screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #4f4f4f;
	color: #ffffff;
}

/* /core/public/shared/assets/css/uitags.css */
.ui-form-legend h3 .ui-form-legend-step {
	background: #ffffff;
	color: #545454;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-basic-view .fc-day-top .fc-week-number {
	background-color: #5e5e5e;
	color: #808080;
}

/* /core/website/modules/events/css/screen.css */
#events_module.cms_list div.events_module_calendar .fc-view-container .fc-event {
	background-color: #9c9c9c;
	color: #000000;
}


/**********  Removing the underline from links  ***********/
/**********************************************************/
/* /core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
.sigNav a.current,
.sigNav a.current:link,
.sigNav a.current:visited {
	text-decoration: underline;
}


/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-list-item-title a {
	text-decoration: underline;
}
